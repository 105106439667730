/**
 * This script looks for elements with the `data-popup-delay` attribute and
 * starts a timer to show the element after the specified delay. If the user
 * interacts with the page by scrolling or clicking, the timer is reset.
 */

const init = () => {
	const elements = document.querySelectorAll('[data-popup-delay]');

	elements.forEach((element) => {
		const delay = Number(element.getAttribute('data-popup-delay')) * 1000;
		let timer: number;

		console.log('init delayed pop-up', delay);

		const showPopup = () => {
			// Remove aria-hidden attribute to make the element visible
			console.debug('showPopup');
			element.removeAttribute('aria-hidden');
		};

		const resetTimer = () => {
			console.log('resetTimer');
			clearTimeout(timer);
			timer = setTimeout(showPopup, delay);
		};

		const cleanup = () => {
			console.log('cleanup');
			clearTimeout(timer);
			window.removeEventListener('scroll', resetTimer);
			window.removeEventListener('click', resetTimer);
			window.removeEventListener('turbo:before-visit', cleanup);
		};

		resetTimer();

		window.addEventListener('scroll', resetTimer);
		window.addEventListener('click', resetTimer);
		window.addEventListener('turbo:before-visit', cleanup);
	});
};

document.addEventListener('turbo:load', init);
